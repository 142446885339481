import React from 'react'

import 'fontsource-nunito-sans/latin.css'
import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'
import SEO from '@/components/SEO'
import Hero from '@/components/Hero'

const Layout = ({
  pageTitle,
  heroTitle,
  heroSubTitle,
  heroTagLine,
  heroImage,
  metaDescription,
  children,
  noIndex
}: {
  pageTitle?: string
  heroTitle: string
  heroSubTitle?: string
  heroTagLine?: string
  heroImage?: any
  metaDescription?: string
  children: React.ReactNode,
  noIndex?: boolean
}) => {
  return (
    <>
      <SEO pageTitle={pageTitle} description={metaDescription} noIndex={noIndex} />

      <Navigation />

      <Hero
        title={heroTitle}
        subtitle={heroSubTitle}
        tagline={heroTagLine}
        image={heroImage}
      />

      <main>
        <div className="container">{children}</div>
      </main>
      <Footer />
    </>
  )
}

export default Layout
