import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetaData = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            shortTitle
            legalEntity
            tagLine
            shortDescription
            fullDescription
            streetNr
            postalcodeCity
            phone
            phoneCC
          }
        }
      }
    `
  )

  return site.siteMetadata
}

export default useSiteMetaData
