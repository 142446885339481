import { useStaticQuery, graphql } from 'gatsby'

const useFallbackImage = () => {
  const { file } = useStaticQuery(
    graphql`
      query FallbackImage {
        file(name: { eq: "dojo-full" }) {
          childImageSharp {
            fluid(maxHeight: 400, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  )

  return file
}

export default useFallbackImage
