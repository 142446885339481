import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Headroom from 'react-headroom'

/**
 * Main component
 */
const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false)

  const toggle = () => {
    setNavOpen(!navOpen)
  }

  const logo = useStaticQuery(graphql`
    query {
      file(name: { eq: "yta-logo" }) {
        childImageSharp {
          fluid(maxHeight: 28, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <header className="header-global">
      <Headroom
        calcHeightOnResize
        disableInlineStyles
        className={`${navOpen ? 'header-active' : ''}`}
      >
        <nav
          id="navbar-main"
          aria-label="Primary navigation"
          className="navbar navbar-main pl-xl-5 pr-xl-5 navbar-theme-white"
        >
          <div className="container-fluid ">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-sm-1O col-xl-3 nav__left">
                <Link className="anchor__simple navbar-brand" to="/">
                  <Img
                    fluid={logo.file.childImageSharp.fluid}
                    loading="eager"
                    fadeIn={false}
                    className=""
                  />
                </Link>
              </div>
              <div className="col-2 col-sm-2 d-xl-none">
                <button
                  className="navbar-toggler collapsed btn--simple"
                  type="button"
                  aria-controls="navbar_global"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggle}
                >
                  <div
                    data-target="navbar_global"
                    className={`hamburger-icon ${navOpen ? ' is-active' : ''}`}
                  >
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                  </div>
                </button>
              </div>

              <div className="col-12 col-sm-12 col-xl-9 nav__right">
                <div className="row align-items-center justify-content-end">
                  <div
                    className={`navbar-collapse collapse ${
                      navOpen ? ' is-active' : ''
                    }`}
                    id="navbar_global"
                  >
                    <ul className="navbar-nav navbar-nav-hover align-items-center">
                      <li className="nav-item">
                        <Link
                          to="/"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/trainingen"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Trainingen
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/club"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Club
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/federatie"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Federatie
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/lesgevers"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Lesgevers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/activiteiten"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Activiteiten
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/organisatie"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Organisatie
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          to="/programmas"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                          className="d-xl-none"
                        >
                          Programma's
                        </Link>

                        <a href="#" className="dropdown__title">
                          Programma's
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                          </svg>
                        </a>
                        <ul className="dropdown__menu">
                          <li className="nav-item">
                            <Link
                              to="/programmas/juniores"
                              aria-label="menu item"
                              activeClassName="nav-item--active"
                            >
                              Juniores
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/programmas/seniores"
                              aria-label="menu item"
                              activeClassName="nav-item--active"
                            >
                              Seniores
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/documenten"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Documenten
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/contact"
                          aria-label="menu item"
                          activeClassName="nav-item--active"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Headroom>
    </header>
  )
}

export default Navbar
