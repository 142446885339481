import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useFallbackImage } from '@/hooks'

type HeroProps = {
  title: string
  subtitle?: string
  tagline?: string
  image?: React.ReactNode
}

export const Hero = ({ title, subtitle, tagline, image }: HeroProps) => {
  const fallbackImage = useFallbackImage()
  let imageData = [
    ` radial-gradient(
      ellipse closest-side,
      rgba(15, 14, 22, 0.5),
      #100e17
    )`,
  ]

  //if there is no image, use the fallback
  image = image || fallbackImage

  //@ts-ignore
  imageData.push(image.childImageSharp.fluid)

  return (
    <BackgroundImage
      Tag="div"
      className="section-header mb-3 text-white"
      fluid={imageData}
      backgroundColor={`#100e17`}
      /*  width="{`75%`}"
      left="auto"*/
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-8">
            <h1
              className="display-1 mb-3"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            ></h1>

            {subtitle && (
              <h2
                className="display-3 mb-3"
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
              ></h2>
            )}
            {tagline && (
              <p
                className="lead tagline"
                dangerouslySetInnerHTML={{
                  __html: tagline,
                }}
              ></p>
            )}
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
