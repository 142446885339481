import React from 'react'
import { Helmet } from 'react-helmet'

import { useSiteMetaData } from '@/hooks'

function SEO({
  pageTitle,
  description,
  noIndex,
}: {
  pageTitle?: string
  description?: string
  noIndex?: boolean
}) {
  const siteMetaData = useSiteMetaData()

  pageTitle = pageTitle?.replace(/(<([^>]+)>)/gi, '')

  return (
    <Helmet>
      <html lang="nl" />
      <title>{pageTitle + ' - Jiu-Jitsu Yukio Tani Aalst'}</title>
      {noIndex && <meta name="robots" content="noindex" />}

      <meta name="color-scheme" content="dark light" />
      <meta
        name="description"
        content={description || siteMetaData.shortDescription}
      />
    </Helmet>
  )
}

export default SEO
