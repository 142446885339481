import React from 'react'
import { Link } from 'gatsby'
import { useSiteMetaData } from '@/hooks'
import YTALink from '@/utilities/YTALink'

export const Footer = () => {
  // Metadata comes from root/config/metadata.js
  const siteMetaData = useSiteMetaData()
  return (
    <footer className="footer site-footer pt-5 pb-6 text-white">
      <div className="container site-footer_inner">
        <div className="row text-center text-md-left">
          <Block
            title="Jiu-Jitsu Club Yukio Tani Aalst"
            sizeClass="col-12 col-md-4 mb-6 mb-lg-0"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: siteMetaData.fullDescription,
              }}
            />
          </Block>

          <Block title="Menu" sizeClass="col-12 col-md-4 mb-6 mb-lg-0">
            <ul className="footer-links mt-2">
              <li>
                <YTALink url="/">{siteMetaData.title}</YTALink>
              </li>
              <li>
                <YTALink url="/trainingen">Trainingen</YTALink>
              </li>
              <li>
                <YTALink url="/club">Club</YTALink>
              </li>
              <li>
                <YTALink url="/federatie">Federatie</YTALink>
              </li>
              <li>
                <YTALink url="/lesgevers">Lesgevers</YTALink>
              </li>
              <li>
                <YTALink url="/activiteiten">Activiteiten</YTALink>
              </li>
              <li>
                <YTALink url="/organisatie">Organisatie</YTALink>
              </li>
              <li>
                <Link to="/programmas">Programma's</Link>
                <ul className="">
                  <li className="">
                    <Link to="/programmas/juniores">- Juniores</Link>
                  </li>
                  <li className="">
                    <Link to="/programmas/seniores">- Seniores</Link>
                  </li>
                </ul>
              </li>
              <li>
                <YTALink url="/documenten">Documenten</YTALink>
              </li>
              <li>
                <YTALink url="/contact">Contact</YTALink>
              </li>
            </ul>
          </Block>

          <Block
            title="Contacteer ons"
            sizeClass="col-12 col-md-4 mb-5 mb-lg-0 justify-content-center"
          >
            <ul className="footer-links mt-2">
              <li>
                <YTALink url="https://goo.gl/maps/wzuPsVhdFkLTDEo28">
                  {siteMetaData.streetNr}, {siteMetaData.postalcodeCity}
                </YTALink>
              </li>
              <li>
                <YTALink url={`tel:${siteMetaData.phoneCC}`}>
                  {siteMetaData.phone}
                </YTALink>
              </li>
            </ul>
          </Block>
        </div>

        <hr className="my-5" />

        <div className="row">
          <BlockBottom>
            Copyright &copy; {new Date().getFullYear()} {siteMetaData.title}{' '}
            {siteMetaData.legalEntity}.
          </BlockBottom>
        </div>
      </div>
    </footer>
  )
}

const Block = ({
  title,
  sizeClass,
  children,
}: {
  title: string
  sizeClass?: string
  children?: React.ReactNode
}) => {
  return (
    <div className={`${sizeClass}`}>
      <h3
        className="h5"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      {children}
    </div>
  )
}

const BlockBottom = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="col mb-md-0">
      <div
        className="d-flex text-center justify-content-center align-items-center"
        role="contentinfo"
      >
        <p className="font-weight-normal font-small mb-0">{children}</p>
      </div>
    </div>
  )
}

export default Footer
