/**
 * Evaluates whether a URL is internal or external and uses the correct tag
 */
import React from 'react'
import { Link } from 'gatsby'

type LinkProps = {
  children?: React.ReactNode
  url: string
}

const YTALink = ({ children, url }: LinkProps) => {
  if ({ children } && { url }) {
    // urls containg ':' are considered external
    const isExternal = url.includes(':')

    if (isExternal) {
      return (
        <a href={`${url}`} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    } else {
      return <Link to={url}>{children}</Link>
    }
  }

  return null
}

export default YTALink
